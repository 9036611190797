/* eslint-disable no-underscore-dangle */
import { useFragment } from 'react-relay';
import Icon from '@/core/icon.js';
import { ORImageSpace as Image } from '@/core/image';
import { useTranslation } from '@/helpers/translate';
import { query } from './card-skin-home';

function SkinHome({ item, options = {} }) {
  const { t } = useTranslation();
  if (!item) return null;
  const classes = {
    lvl0: 'carousel-cell-space-home md:cursor-pointer',
  };
  if (options.lvl0) {
    classes.lvl0 = options.lvl0;
  }
  function getUrl() {
    return `${item.urlByLang}`;
  }

  function getImageProps() {
    return options.imageProps || {};
  }

  return (
  <a
    className={classes.lvl0}
    href={getUrl()}
  >
  {options.contentPrice || null}
    <div>
    {!item?.pictures?.length || (
        <Image
          {...getImageProps()}
          key={item.pictures[0].__id}
          defaultAlt={item.title}
          cardsSpacesCities
          picture={item.pictures[0]}
        />
    )}
    </div>
    <div className="rounded-b-lg absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-black/[0] opacity-90" />
        <div className="absolute flex items-center bottom-4 right-4 left-4">
          <div className="pb-1 grow">
            <div className="text-balance overflow-hidden overflow-ellipsis text-white line-clamp-3 leading-5 text-[15px]">
              {item.title}
            </div>
            <div className="block mt-3 text-sm">
              <span className="bg-black bg-opacity-70 px-[10px] py-[6px] rounded text-white">
                <span className="font-medium">{item?.p?.price?.amount_excl_tax}€ </span>
                <span className="font-light">{t('core:pricebyday')}</span>
              </span>
            </div>
          </div>
          {!item.reviewCounter || (
          <div className="h-5 pl-2">
            <div className="flex flex-col items-center justify-center">
              <div className="flex">
                {!item.rate || (
                  <Icon name="star" className="w-3 fill-current text-blue-light2" />
                )}
                <span className="mx-1 text-sm text-blue-light2">{item.rate}</span>
              </div>
            </div>
          </div>
          )}
        </div>
  </a>
  );
}

export { SkinHome };

export default function RootComponent({ item: itemData, ...props }) {
  const item = useFragment(query, itemData);

  return <SkinHome {...{ item }} {...props} />;
}
